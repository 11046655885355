import React from "react"


import footerStyles from "../styles/footer.module.css"

export default function Footer(){

    return <div className={footerStyles.footer} >
        {/* Built with <TiHeartFullOutline color="red" /> */}
          Created by Yash Jajoo, &copy; {new Date().getFullYear()} 
    </div> 
    
}